import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../Firebase';
import './Home.css'

const Home = () => {
 
  useEffect(()=>{
      onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            // ...
            console.log("uid", uid)
          } else {
            // User is signed out
            // ...
            console.log("user is logged out")
          }
        });
       
  }, [])

return (
  <>        
      <div className='h-screen bg-slate-600 flex justify-center items-center'>
        <div className='flex w-4/5 justify-between'>
          <div className='h-40 w-40 bg-slate-100 text-red-600'></div>
          <div className='h-40 w-40 bg-slate-100 text-red-600'></div>
        </div>
      </div>
    
  </>
)
}

export default Home

