import React from 'react'
import './Navbar.css'
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../../Firebase';
function Navbar() {
    const navigate =useNavigate();
    const go_menu=()=>{navigate('/Menu');}
    const go_addcategory=()=>{navigate('/AddCategory');}


    const handleLogout = () => {               
      signOut(auth).then(() => {
      // Sign-out successful.
          navigate("/");
          console.log("Signed out successfully")
      }).catch((error) => {
      // An error happened.
      });
  }

  return (
    <>
        <div className='admin-outer'>
            <div className='admin-logo hover:cursor-pointer' onClick={()=>{navigate("/home")}}>Admin Pannel</div>
            <div className='nav-items'>
                <div className='nav-item' onClick={go_menu}>ADD MENU ITEMS</div>
                <div><button onClick={handleLogout}>Logout</button></div>
                <div className='nav-item' onClick={go_addcategory}>ADD CATEGORY</div>
            </div>
        </div>
    </>
  )
}

export default Navbar
