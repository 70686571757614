import Navbar from "./Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Components/Home/Home";
import Menu from "./Components/Menu/Menu";
import AddCategory from "./Components/AddCategory/AddCategory";
import Addsubcategory from './Components/SubCategory/Addsubcategory';
import AddListing from "./Components/Listing/AddListing";
import Login from "./Components/Login/Login";
import { auth } from "./Firebase";

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [id, setId] = useState(null);
  const [cate, setCate] = useState(null);
  const [lid, setLId] = useState(null);
  const [lcate, setLcate] = useState(null);
  const [send_ref, setSend_ref] = useState(null);

  const data_from_child = (id, category) => {
    setId(id);
    setCate(category);
  }

  const data_from_child1 = (id, category, ref) => {
    setLId(id);
    setLcate(category);
    setSend_ref(ref);
  }

  const fetchUserdata = () => {
    auth.onAuthStateChanged(async (user) => {
      console.log(user);
      setUser(user);
    })
  }

  useEffect(() => { fetchUserdata() }, []);

  useEffect(() => {
    if (!user) navigate("/");
  }, [user, navigate]);

  return (
    <div>
      {user && <Navbar />}
      <Routes>
        <Route exact path="/" element={<Login />} />
        {user && (
          <>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/Menu" element={<Menu />} />
            <Route exact path="/AddCategory" element={<AddCategory data_from_parent={data_from_child} />} />
            <Route exact path="/Addsubcategory" element={id && <Addsubcategory data_from_parent1={data_from_child1} id={id} cate={cate} />} />
            <Route exact path="/AddListing" element={send_ref && <AddListing id={lid} cate={lcate} send_ref={send_ref} />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}


// import Navbar from "./Components/Navbar/Navbar";
// import React, { useEffect, useState } from "react";
// import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
// import Home from "./Components/Home/Home";
// import Menu from "./Components/Menu/Menu";
// import AddCategory from "./Components/AddCategory/AddCategory"
// import Addsubcategory from './Components/SubCategory/Addsubcategory'
// import AddListing from "./Components/Listing/AddListing";
// import Login from "./Components/Login/Login";
// import { auth } from "./Firebase";
// import { useNavigate } from "react-router-dom";
// function App() {
//   const navigate =useNavigate();
//   const [user,setUser]=useState(null);
//   const [id,setId]=useState(null);
//   const [cate,setCate]=useState(null);
//   const [lid,setLId]=useState(null);
//   const [lcate,setLcate]=useState(null);
//   const [send_ref,setSend_ref]=useState(null);
//   const data_from_child=(id,category)=>{
//     setId(id);
//     setCate(category);
//   }
//   // const [cate,setCate]=useState(null)
//   const data_from_child1=(id,category,ref)=>{
//     setLId(id);
//     setLcate(category);
//     setSend_ref(ref);

//   }

//   const fetchUserdata=()=>{
//     auth.onAuthStateChanged(async (user)=>{
//       console.log(user);
//      setUser(user);
//     })
//   }

//   useEffect(()=>{fetchUserdata()},[])
//   return (
//     <>
//     {!user ?navigate("/") :null}
//     {user && <div>
//       <Router>
//        <Navbar/>
//       {/* <Home/> */}
//       <Routes>
//       <Route
//           exact
//           path="/"
//           element={<Login/>}
//         ></Route>
//         <Route
//           exact
//           path="/home"
//           element={<Home/>}
//         ></Route>
//         <Route
//           exact
//           path="/Menu"
//           element={<Menu/>}
//         ></Route>
//         <Route
//           exact
//           path="/AddCategory"
//           element={<AddCategory data_from_parent={data_from_child}/>}
//         ></Route>
//         <Route
//           exact
//           path="/Addsubcategory"
//           element={id&&<Addsubcategory data_from_parent1={data_from_child1} id={id} cate={cate}/>}
//         ></Route>
//         <Route
//           exact
//           path="/AddListing"
//           element={send_ref&&<AddListing id={lid} cate={lcate} send_ref={send_ref}/>}
//         ></Route>
//       </Routes>
//     </Router>
//     </div>}
//     </>
    
//   );
// }

// export default App;
